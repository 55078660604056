@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("./Inter-Regular.woff2");
}
body {
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}
.calendar-icon {
  padding: 0.5em;
  margin: 1em auto;
  cursor: pointer;
}
#calendar {
  margin: 0 auto;
  height: 225px;
  width: auto;
  color: #2196f3;
  padding-bottom: 20px;
}
.MuiTableRow-root.MuiTableRow-hover:hover  {
  background-color: #2196f3!important;
}
.MuiTableRow-root.MuiTableRow-hover:hover  > .MuiTableCell-body {
  color: white;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.a {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    100ms both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms
    both;
}
.b {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    20ms both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 100ms both;
}

.c {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    250ms both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms
    both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #2196f3;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin-right: 5px;
  box-shadow: inset 0px 0px 0px #2196f3;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #2196f3;
  }
}
.btn-perso {
  text-transform: uppercase;
  color: #000;
  background-color: #3f51b5;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 0;
  transition: all 0.8s;
}

.btn.active,
.btn:active,
.btn:focus,
.btn:hover {
  background-color: #d43031;
  transition: all 0.8s;
}

.text-center {
  text-align: center;
  color: #2196f3;
  padding-top: 1.5em;
}

.justify-center {
  display: flex;
justify-content: center;
}
.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.RaTreeCustomMenuItem-menuItem-130.RaTreeCustomMenuItem-openMenuItem-132.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background:#165991;
}